import './index.css';
import reportWebVitals from './reportWebVitals';

/* const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
); */

async function init() {
  /*
  root.render(
    <React.StrictMode>
      <HashRouter>
        <App />
      </HashRouter>
    </React.StrictMode>,
  );
  */

  // Redirect til Bekk.no til vi får bedre kontroll på utregningene som gjøres i kalkulatoren
  window.location.href = 'https://bekk.no';
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
init();
